import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1019.000000 1017.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1017.000000) scale(0.100000,-0.100000)">
          
          <path d="M4780 9290 c-539 -43 -1012 -198 -1465 -479 -494 -306 -907 -761
-1170 -1290 -379 -763 -437 -1651 -161 -2456 255 -746 790 -1381 1484 -1764
475 -262 971 -391 1517 -395 162 -1 402 11 393 19 -2 2 -59 13 -127 25 -237
40 -532 128 -726 215 -311 140 -561 294 -790 488 -604 512 -993 1210 -1106
1982 -140 961 157 1919 817 2639 105 115 128 137 254 247 444 384 998 643
1571 736 112 18 113 18 64 25 -88 11 -447 17 -555 8z"/>
<path d="M5155 9020 c-59 -7 -50 -10 95 -35 635 -107 1271 -469 1688 -960 42
-49 82 -96 88 -102 36 -38 182 -256 246 -368 194 -340 305 -672 365 -1090 21
-148 24 -541 5 -690 -47 -372 -131 -654 -292 -980 -359 -729 -1024 -1284
-1809 -1510 -123 -35 -362 -85 -408 -85 -20 0 -24 -3 -16 -11 18 -18 461 -25
608 -9 804 82 1518 471 2020 1100 331 415 544 921 617 1470 17 126 17 576 0
700 -67 488 -215 885 -478 1280 -118 178 -198 275 -364 440 -169 170 -262 247
-455 375 -398 265 -833 422 -1317 474 -103 12 -508 12 -593 1z"/>
<path d="M3660 7990 l0 -300 518 -2 517 -3 2 -95 c1 -52 2 -98 2 -102 1 -4 20
0 44 8 151 54 449 84 620 62 l107 -14 0 73 0 73 525 0 525 0 0 300 0 300
-1430 0 -1430 0 0 -300z"/>
<path d="M5065 7484 c-184 -20 -321 -57 -460 -124 -179 -86 -318 -214 -396
-362 -65 -127 -80 -183 -86 -343 -5 -129 -3 -148 20 -237 81 -303 230 -449
740 -727 275 -150 416 -251 497 -355 52 -68 69 -114 70 -188 1 -154 -116 -295
-295 -356 -238 -80 -508 -36 -902 148 l-101 48 -6 -57 c-4 -31 -15 -138 -26
-238 -11 -100 -20 -193 -20 -206 0 -22 10 -27 138 -65 158 -48 384 -104 492
-123 91 -16 314 -16 427 0 380 55 690 257 836 548 100 197 115 502 34 703 -98
242 -282 398 -762 647 -245 128 -372 211 -453 298 -80 85 -95 120 -89 211 8
147 102 238 297 291 91 25 349 24 475 0 108 -22 232 -58 338 -99 42 -17 79
-29 80 -27 5 5 48 418 47 450 0 24 -6 27 -177 72 -273 72 -369 88 -533 92 -80
2 -163 1 -185 -1z"/>
<path d="M5397 4280 c-105 -41 -263 -64 -437 -65 -91 0 -191 3 -222 8 l-58 7
0 -160 0 -160 390 0 390 0 0 195 c0 107 -3 195 -7 195 -5 -1 -29 -9 -56 -20z"/>
<path d="M6191 1788 l1 -623 45 95 c24 52 46 96 48 98 2 2 32 -12 68 -32 101
-54 187 -77 313 -83 88 -5 121 -2 162 11 103 35 136 124 67 184 -36 32 -88 51
-275 97 -194 48 -268 82 -332 153 -112 126 -97 327 35 453 40 39 145 92 217
110 83 21 316 19 415 -5 91 -21 205 -70 205 -89 0 -27 -76 -207 -88 -207 -7 0
-32 10 -54 21 -84 43 -152 61 -254 66 -160 9 -249 -33 -261 -123 -10 -77 44
-110 272 -164 138 -33 254 -73 305 -105 119 -75 167 -212 124 -355 -34 -116
-117 -192 -262 -244 -83 -29 -94 -30 -247 -31 -185 0 -285 20 -415 83 -41 21
-78 39 -82 40 -5 2 -8 -55 -8 -127 l0 -131 1690 0 1690 0 0 157 0 157 -47 -46
c-27 -26 -73 -59 -105 -73 -106 -48 -177 -55 -579 -55 l-369 0 0 621 0 620
383 -3 c359 -4 386 -5 446 -25 79 -27 161 -82 194 -131 38 -56 50 -106 45
-187 -6 -90 -36 -152 -101 -210 l-47 -43 45 -23 c26 -13 66 -44 91 -71 l44
-47 0 444 0 445 -1690 0 -1690 0 1 -622z m1331 80 c5 -446 7 -462 82 -542 95
-101 293 -101 391 0 68 69 68 72 75 524 l5 405 143 3 142 3 0 -380 c0 -294 -3
-397 -15 -457 -53 -279 -261 -425 -585 -411 -236 10 -389 101 -470 280 -47
104 -51 146 -57 570 l-5 397 145 0 145 0 4 -392z"/>
<path d="M590 2145 l0 -115 195 0 195 0 0 -505 0 -505 145 0 145 0 0 505 0
505 190 0 190 0 0 -505 0 -505 140 0 140 0 0 620 0 620 -670 0 -670 0 0 -115z"/>
<path d="M2060 1640 l0 -620 130 0 130 0 0 357 c0 311 2 355 14 343 8 -8 91
-139 184 -290 l170 -275 64 -3 65 -3 179 298 179 298 6 -255 c3 -140 7 -303 8
-362 l1 -108 130 0 130 0 0 620 0 620 -117 0 -118 0 -166 -283 c-92 -155 -193
-328 -225 -384 -33 -56 -61 -102 -64 -103 -3 0 -109 173 -235 385 l-230 384
-117 1 -118 0 0 -620z"/>
<path d="M3580 1640 l0 -620 130 0 130 0 0 357 c0 311 2 355 14 343 8 -8 91
-139 184 -290 l170 -275 64 -3 65 -3 179 298 179 298 6 -255 c3 -140 7 -303 8
-362 l1 -108 130 0 130 0 1 573 c0 379 3 567 10 557 5 -8 102 -169 214 -357
l205 -342 0 -216 0 -215 140 0 140 0 0 216 0 216 206 347 c114 190 216 362
229 381 12 19 27 45 34 58 l12 22 -139 0 -140 0 -162 -275 c-89 -151 -165
-275 -169 -275 -5 0 -91 141 -266 438 l-66 112 -242 0 -242 0 -166 -283 c-92
-155 -193 -328 -225 -384 -33 -56 -61 -102 -64 -103 -3 0 -109 173 -235 385
l-230 384 -117 1 -118 0 0 -620z"/>
<path d="M8755 2028 c-3 -8 -4 -72 -3 -144 l3 -129 190 0 c167 0 195 2 229 19
102 51 101 196 -1 243 -52 23 -410 33 -418 11z"/>
<path d="M8750 1390 l0 -152 218 4 c197 3 221 5 264 25 56 26 78 63 78 131 0
38 -5 55 -25 76 -50 54 -81 60 -317 64 l-218 4 0 -152z"/>

</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
